<template>
  <div>   
    <center>
        <br><br> 
        <b-row class="justify-content-center">
            <b-col cols="10" md="4" style="background: #0c273a; border-radius: 10px;">  
            <b-row class="justify-content-center" style=" margin: 5px;" >  
                <b-col cols="10" md="3" class="mt-1">  
                    <u style="color:#fff; font-size:20px; cursor: pointer;" @click="cambiaTab(0)">LÍDER</u>
                </b-col> 
                <b-col cols="10" md="7"  class="mt-1">   
                <input v-model="buscar_lider" type="text" class="form-control" placeholder="Buscar"  style="border-radius: 5px; height: 90%;"> 
                </b-col> 
            </b-row>  
            </b-col> 
        </b-row>
    </center>
     <br> <br>
     <b-row class="justify-content-center">
        <b-col cols="11" md="10" class="mt-2">    
            <b-tabs content-class="mt-3" justified style="font-size:26px;" >
                <b-tab title="Regiones" :active="region_pdv == 'Todas' ? true : false" @click="cambiaRegion('Todas')"></b-tab> 
                <b-tab title="Centro F." :active="region_pdv == 'CENTRO FORANEO' ? true : false" @click="cambiaRegion('CENTRO FORANEO')"></b-tab>
                <b-tab title="Centro M." :active="region_pdv == 'CENTRO METROPOLITANO' ? true : false" @click="cambiaRegion('CENTRO METROPOLITANO')"></b-tab>
                <b-tab title="Norte" :active="region_pdv == 'NORTE' ? true : false" @click="cambiaRegion('NORTE')"></b-tab>
                <b-tab title="Occidente" :active="region_pdv == 'OCCIDENTE' ? true : false" @click="cambiaRegion('OCCIDENTE')"></b-tab>
                <b-tab title="Sur" :active="region_pdv == 'SUR' ? true : false" @click="cambiaRegion('SUR')"></b-tab>
            </b-tabs>    
        </b-col>
    </b-row> 
     <div style="background:#d6eaf83b; margin-top: -35px;"> 
        <b-row class="justify-content-center mt-5">
            <b-col cols="11" md="11" lg="11" class="text-center mt-3"> 
                <center v-if="loader">
                    <SpinnerLoader/>
                    <p style="color:#000;">Procesando información, no cierres el navegador <br> espera un momento...</p>
                </center>  
                <template> 
                    <div>
                            <table class="table table-borderless mt-3 text-center">
                                <thead class="text-white">
                                <tr>  
                                    <td style="width:4%; font-size:24px; color:#0b2739;"  class="text-center"><b><u>Lugar</u></b></td>  
                                    <td style="width:12%; font-size:24px; color:#0b2739;"><b><u>Líder</u></b></td>    
                                    <td style="width:17%; font-size:24px; color:#0b2739;" class="text-center"><b><u>Cumplimiento</u></b></td> 
                                    <td style="width:4%;  font-size:24px;  color:#0b2739;"><b><u>Puntos</u></b></td>                                 
                                </tr>
                                </thead>
                                <tbody>
                                <br>
                                <tr  v-for="(ran, index) in Lider" :key="ran.id + '-lider'">
                                    <td  class="text-center" >
                                        <div style="color: #fff; background: #3bcb96; ">
                                            <b-row class="justify-content-center" style="margin-top:-100px">
                                                <b-col cols="10" :style="index == 0 ? 'margin-top:35px;' : 'margin-top:17px;'"> 
                                                    <br > <br > <br> 
                                                    <template v-if="!buscar_lider">
                                                        <img v-if="index < 10" :src="'https://convencioncomercial.com.mx/plato/'+ index + '.png'" class="rounded" alt="Image" style="width:50%; margin-top:-2px">  
                                                    <h5 v-if="index > 9" style="color: #fff; margin-top:4px;" >
                                                            {{index + 1  }}
                                                        </h5>  
                                                    </template>
                                                    <template v-else>
                                                        <img v-if="ran.lugar < 10" :src="'https://convencioncomercial.com.mx/plato/'+ (ran.lugar - 1)  + '.png'" class="rounded" alt="Image" style="width:50%;  margin-top:-2px;">  
                                                        <h4 v-if="ran.lugar > 9" style="color: #fff; margin-top:4px;" >
                                                            {{ran.lugar}}
                                                        </h4>  
                                                    </template>
                                                    <br > <br > <br> 
                                                </b-col>
                                            </b-row>
                                        </div>
                                    </td>
                                    <td style="color: #0c273a; font-size: 24px;"> 
                                        <div  :style="index == 0 ? 'margin-top:20px;' : 'margin-top:2px;'">
                                            {{ran.lider}} 
                                        </div>  
                                    </td> 
                                    <td> 
                                        <b style="font-size:13px;">
                                        <b-row class="justify-content-center" style="margin-top:-75px;"> 
                                            <b-col cols="10">
                                                <b-row  >
                                                <b-col cols="1" ></b-col>
                                                <b-col cols="2" >
                                                     <br><br> <span  style="text-transform:capitalize; font-size:24px;  color:#0b2739;" v-if="index == 0"><u>Marzo</u></span><br> 
                                                    <h4 :style="isMobile ? 'color: #fff; font-size:16px;' : 'color: #fff; font-size:28px;'" class="mt-5" >
                                                        <b>{{ran.marzo}}</b> 
                                                    </h4>
                                                    <br>
                                                    <br> 
                                                </b-col> 
                                                <b-col cols="2">
                                                    <br><br> <span style="text-transform:capitalize; font-size:24px;  color:#0b2739;" v-if="index == 0"><u>Abril</u></span><br>  
                                                    <h4 :style="isMobile ? 'color: #fff; font-size:15px;' : 'color: #fff; font-size:28px;'"  class="mt-5">
                                                        <b>{{ran.abril}}</b>
                                                    </h4>
                                                    <br>
                                                    <br> 
                                                </b-col>
                                                <b-col cols="2">
                                                    <br><br> <span style="text-transform:capitalize; font-size:24px;  color:#0b2739;" v-if="index == 0"><u>Mayo</u></span><br> 
                                                    <h4 :style="isMobile ? 'color: #fff; font-size:15px;' : 'color: #fff; font-size:28px;'"  class="mt-5">
                                                        <b>{{ran.mayo}}</b>
                                                    </h4>
                                                    <br>
                                                    <br>
                                                </b-col>
                                                <b-col cols="2">
                                                    <br><br><span style="text-transform:capitalize; font-size:24px;  color:#0b2739;  margin-left: -10px;" v-if="index == 0"><u>Junio</u></span><br>  
                                                    <h4 :style="isMobile ? 'color: #fff; font-size:15px; margin-left: -10px;' : 'color: #fff; font-size:28px; margin-left: -10px;'"  class="mt-5">
                                                        <b>{{ran.junio}}</b>
                                                    </h4>
                                                    <br>
                                                    <br> 
                                                </b-col> 
                                                <b-col cols="2">
                                                    <br><br><span style="text-transform:capitalize; font-size:24px;  color:#0b2739;  margin-left: -10px;" v-if="index == 0"><u>Julio</u></span><br>  
                                                    <h4 :style="isMobile ? 'color: #fff; font-size:15px; margin-left: -10px;' : 'color: #fff; font-size:28px; margin-left: -10px;'"  class="mt-5">
                                                        <b>{{ran.julio}}</b>
                                                    </h4>
                                                    <br>
                                                    <br> 
                                                </b-col> 
                                                <b-col cols="1" ></b-col>
                                            </b-row>
                                            </b-col> 
                                        </b-row>
                                        </b> 
                                    </td> 
                                    <td> 
                                        <div style="color: #fff; background: #3bcb96;">
                                            <b-row class="justify-content-center" :style="index == 0 ? 'margin-top:-105px' : 'margin-top:-122px'">
                                            <b-col cols="10" >
                                                <br v-if="!isMobile"><br v-if="!isMobile"><br><br> <br>
                                                <b style="font-size: 28px;">{{ran.total}} </b> 
                                                <br > <br > <br>  
                                            </b-col>
                                            </b-row>
                                        </div>
                                    </td>
                                </tr>
                                </tbody>
                            </table> 
                            <!-- <template v-if="region_pdv == 'Todas'">
                                <br>
                                <h6 v-if="Lider.length > 9 &&  vermas < arrayLider2.length"  style="cursor: pointer;" @click="getcampanas(vermas + 10)"><u>Ver más <b-spinner v-if="loader" small></b-spinner></u></h6>
                                <br>
                                <span v-if="vermas > 10 && !buscar_lider" @click="subirArriba()" class="mdi mdi-arrow-up-thick " style="color:#fff; cursor: pointer;"> Regresar al Inicio</span> 
                            </template> -->
                    </div> 
                </template> 
            </b-col>
        </b-row>
     </div>
  </div>
</template>

<script>
function compare(a, b) {
  if (a.total > b.total) {
    return -1;
  }
  if (a.total < b.total) {
    return 1;
  }
  return 0;
}

import SpinnerLoader from "../../utils/SpinnerLoader.vue";
export default {
  name: 'Propio',
  components:{
    SpinnerLoader
  },
  data(){
    return{
      isMobile: this.$mobile(),
      loader:false,
      arrayLider:[],
      arrayKams:[], 
      arrayLider2:[],
      arrayKams2:[], 
      tab:0,
      colors:'#83c32d',
      buscar_lider:"",
      buscar_kams:"", 
      vermas:10,
      region_pdv:"Todas",
      CentroF:[],
      CentroM:[],
      Occidente:[],
      Norte:[],
      Sur:[],
      Array:[]
    }
  },
  computed: {
    user() {
      return this.$store.getters.getInfoUser;
    },
    Kams(){
        if (this.buscar_kams == '') {          
                return this.arrayKams;
            }else{
                return   this.arrayKams2.filter(item => {
                        return   item.kams?.toLowerCase().includes(this.buscar_kams.toLowerCase())
                        ;
                });
            }
    },
    Lider(){
        if (this.buscar_lider == '') {          
                return this.Array
            }else{
                return   this.Array.filter(item => {
                        return   item.lider?.toLowerCase().includes(this.buscar_lider.toLowerCase())
                        ;
                });
            }
    }, 
  },
  methods:{
    cambiaRegion(id){
         this.region_pdv = id;
         this.buscar_lider = "";
         switch (this.region_pdv ) {
            case "Todas":
                this.Array = this.arrayLider2; 
            break; 
            case "CENTRO FORANEO":
                 this.Array = this.CentroF; 
            break; 
            case "CENTRO METROPOLITANO":
                this.Array = this.CentroM; 
            break; 
            case "NORTE":
                this.Array = this.Norte; 
            break; 
            case "OCCIDENTE":
                this.Array = this.Occidente; 
            break; 
            case "SUR":
                this.Array = this.Sur; 
            break; 
         }
    },
    cambiaTab(id){
        this.cambiaRegion('Todas');
        this.tab = id;
        this.buscar_kams = "";
        this.buscar_lider = "";
    },
    subirArriba(){
      // this.$scrollTo("#subir");
       window.scrollTo(0, -500);
    },
    getcampanas(id){
      this.loader = true;
      this.vermas = id;
      this.buscar_kams = '';
      this.buscar_lider = ''; 
      var url= 'auth/rankinespecialista?vermas='+ id;
        this.$api.get(url).then(
            ({data}) => {
                console.log(data);
                this.arrayLider = data.lider.sort(compare); 
                this.arrayLider2 = data.lider2.sort(compare); 
                this.CentroF = data.centrof.sort(compare); 
                this.CentroM = data.centrom.sort(compare); 
                this.Occidente = data.occidente.sort(compare); 
                this.Norte = data.norte.sort(compare); 
                this.Sur = data.sur.sort(compare); 
                this.arrayKams = data.kams.sort(compare); 
                this.arrayKams2 = data.kams2.sort(compare); 
                this.cambiaRegion('Todas');
                this.loader = false;
            }
        );
    },
    regresarHome(){
        this.$bus.$emit("cambia_fondo2", true)
        this.$router.push("/"); 
    }
  },
  mounted(){
    this.$bus.$emit("cambia_fondo", true)
            if (this.isMobile)
      {
        this.$bus.$emit("cambi_fo5", true)
        //  window.scrollTo(0, -200);     
      } 
    this.getcampanas(this.vermas);
  }
}
</script>
<style>
.table {
--bs-table-bg: #fff0; 
}

.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
    color: #0c273a;
    background-color: #33d951;
    border-color: #33d951;
}

.nav-tabs .nav-link { 
    background: #686c6f;
    border: 4px   solid #019df4;
    border-top-left-radius: 0.8rem;
    color: #fff;
    border-top-right-radius: 0.8rem; 
}

#outer_wrapper {  
    overflow: scroll;  
    width:100%;
}
#outer_wrapper #inner_wrapper {
    width:1000px; /* If you have more elements, increase the width accordingly */
}
#outer_wrapper #inner_wrapper div.box { /* Define the properties of inner block */
    width: 250px;
    height:300px;
    float: left;
    margin: 0 4px 0 0;
    border:1px grey solid;
}

.nav-tabs {
    --bs-border-width: 0px;
}
</style>